
//==========  List styles  ==========//

// list item styles
%default-list {
    padding: 0 0 0 $block-padding;
    margin: 0 0 $paragraph-margin;
}

%default-ul {
    list-style: disc;
    @extend %default-list;
}

%default-ol {
    list-style: decimal;
    @extend %default-list;
}

// list item reset
%reset-ul {
    list-style: none;
    padding: 0;
    margin: 0;
}


//==========  Buttons and default styles  ==========//

// base button styles
// extend for different button styles
%button {
    border: none;
    background: $color-primary;
    color: $color-white;
    text-align: center;
    transition: $transition;
    @extend %uppercase-text;
    font-weight: $font-bold;
    font-size: $font-size-body - 0.2rem;
}

%hover {
    background: $color-tertiary;
    color: $color-white;
}

// extend for default .read-more
%read-more {
    @extend %button;
    @extend %staff-titles;
    margin: 0;
    display: inline-block;
    padding: ($site-padding - 0.5rem) ($site-padding + 0.5rem);

    &:hover {
        @extend %hover;
    }
}

