html {
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  font-family: sans-serif;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%; }

*, *:before, *:after {
  box-sizing: inherit; }

body {
  margin: 0; }

article, aside, details, figcaption, figure, footer, header, hgroup, main, nav, section, summary {
  display: block; }

audio, canvas, progress, video {
  display: inline-block;
  vertical-align: baseline; }

audio:not([controls]) {
  display: none;
  height: 0; }

[hidden], template {
  display: none; }

a {
  background: transparent; }

a:active, a:hover {
  outline: 0; }

abbr[title] {
  border-bottom: 1px dotted; }

b, strong {
  font-weight: bold; }

dfn {
  font-style: italic; }

h1 {
  font-size: 2em;
  margin: .67em 0; }

mark {
  background: #ff0;
  color: #000; }

small {
  font-size: 80%; }

sub, sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline; }

sup {
  top: -0.5em; }

sub {
  bottom: -0.25em; }

img {
  border: 0; }

svg:not(:root) {
  overflow: hidden; }

figure {
  margin: 1em 40px; }

hr {
  -moz-box-sizing: content-box;
  box-sizing: content-box;
  height: 0; }

pre {
  overflow: auto; }

code, kbd, pre, samp {
  font-family: monospace,monospace;
  font-size: 1em; }

button, input, optgroup, select, textarea {
  color: inherit;
  font: inherit;
  margin: 0; }

button {
  overflow: visible; }

button, select {
  text-transform: none; }

button, html input[type="button"], input[type="reset"], input[type="submit"] {
  -webkit-appearance: none;
  cursor: pointer; }

button[disabled], html input[disabled] {
  cursor: default; }

button::-moz-focus-inner, input::-moz-focus-inner {
  border: 0;
  padding: 0; }

input {
  line-height: normal; }

input[type="checkbox"], input[type="radio"] {
  box-sizing: border-box;
  padding: 0; }

input[type="number"]::-webkit-inner-spin-button, input[type="number"]::-webkit-outer-spin-button {
  height: auto; }

input[type="search"] {
  -webkit-appearance: textfield;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box; }

input[type="search"]::-webkit-search-cancel-button, input[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none; }

fieldset {
  border: none;
  padding: 0;
  margin: 0; }

legend {
  border: 0;
  padding: 0; }

textarea {
  overflow: auto; }

optgroup {
  font-weight: bold; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

td, th {
  padding: 0; }

@keyframes hippyshake {
  0% {
    transform: translateX(2px); }
  20% {
    transform: translateX(0); }
  40% {
    transform: translateX(-2px); }
  60% {
    transform: translateX(0); }
  80% {
    transform: translateX(2px); }
  100% {
    transform: translateX(0); } }

@keyframes loading {
  50% {
    transform: scale(1);
    opacity: 1; }
  100% {
    opacity: 0; } }

.hero .inner .profile-image-wrapper .profile-image {
  display: block;
  width: 100%; }

.hidden, .cms-toolbar, .mobile-ui, .header .menu-toggle,
.header .nav, .header .inner *, .hero .inner *, .column.right, .column.left, .pre-footer, .search-container, .social-icons,
.footer-links,
.footer-block, .cookies {
  display: none; }

.loading {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%); }
  .loading p {
    color: #fff; }
  .loading .dot {
    width: 14px;
    height: 14px;
    margin: 0 7px;
    background: #fff; }
  .loading p {
    margin: 0;
    text-align: center; }
  .loading .dot {
    display: inline-block;
    transform: scale(0);
    border-radius: 50%;
    animation: loading 1s ease infinite; }
    .loading .dot:nth-child(2) {
      animation: loading 1s ease infinite 0.3s; }
    .loading .dot:nth-child(3) {
      animation: loading 1s ease infinite 0.6s; }

.button {
  text-transform: uppercase;
  letter-spacing: 1px; }

@keyframes scroll {
  0% {
    opacity: 0; }
  10% {
    transform: translateY(0);
    opacity: 1; }
  100% {
    transform: translateY(15px);
    opacity: 0; } }

.block-text ul,
.biography ul, .block-text ol,
.biography ol {
  padding: 0 0 0 1.5rem;
  margin: 0 0 2rem; }

.block-text ul,
.biography ul {
  list-style: disc; }

.block-text ol,
.biography ol {
  list-style: decimal; }

ul, ol, .block-text ul[class],
.biography ul[class] {
  list-style: none;
  padding: 0;
  margin: 0; }

.button {
  border: none;
  background: #00204e;
  color: #fff;
  text-align: center;
  transition: all 0.3s ease-out;
  font-weight: 700;
  font-size: 0.9rem; }

.button:hover {
  background: #93afb1;
  color: #fff; }

body {
  color: #5d5c58;
  font-family: "Cormorant Infant", serif;
  font-size: 1.1rem;
  font-weight: 500; }

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  margin: 0 0 2rem;
  color: #00204e;
  font-family: "Cormorant Infant", serif;
  font-weight: 400;
  line-height: 1.3; }

h1, .h1 {
  font-size: 2.8rem; }

h2, .h2 {
  font-size: 2.2rem; }

h3, .h3 {
  font-size: 1.7rem; }

h4, .h4 {
  font-size: 1.5rem; }

h5, .h5 {
  font-size: 1.3rem; }

h6, .h6 {
  font-size: 1.1rem; }

h1, .h1 {
  font-size: 2rem; }
  @media only screen and (min-width: 50em) {
    h1, .h1 {
      font-size: 2.8rem; } }

h2, .h2 {
  font-size: 2rem; }
  @media only screen and (min-width: 50em) {
    h2, .h2 {
      font-size: 2.2rem; } }

p {
  margin: 0; }

strong {
  font-weight: 700; }

hr {
  border: 1px solid #eee; }

a {
  color: #00204e;
  text-decoration: none;
  font-weight: 700;
  transition: all 0.2s ease-out; }
  a:hover {
    color: #b8b4a5; }

.table-scroll {
  overflow: scroll;
  margin: 0 0 2rem; }
  .table-scroll table {
    width: 100%; }
    .table-scroll table th {
      padding: 1rem 1.5rem;
      border: 2px solid #001635;
      background: #00204e;
      color: #fff;
      font-family: "Cormorant Infant", serif; }
    .table-scroll table td {
      padding: 1.5rem;
      border: 2px solid #eee;
      vertical-align: top; }
    .table-scroll table p {
      margin: 0; }

.block-text,
.biography {
  line-height: 1.7; }
  .block-text p,
  .biography p {
    margin: 0 0 2rem; }
    .block-text p.intro,
    .biography p.intro {
      padding: 0 0 2rem;
      border-bottom: 1px solid #eee;
      color: #5d5c58;
      font-family: "Cormorant Infant", serif;
      font-size: 1.5rem;
      font-weight: 500; }
      @media only screen and (min-width: 40em) {
        .block-text p.intro,
        .biography p.intro {
          font-size: 1.6rem; } }
  .block-text ul[class],
  .biography ul[class] {
    margin: 0 0 2rem; }

.section-library .block-text {
  font-size: 1.2rem; }

.button {
  display: inline-block;
  padding: 0.5rem 3rem; }
  .button a {
    color: inherit;
    transition: inherit; }

.hidden, .cms-toolbar, .mobile-ui, .header .menu-toggle,
.header .nav, .header .inner *, .hero .inner *, .column.right, .column.left, .pre-footer, .search-container, .social-icons,
.footer-links,
.footer-block, .cookies {
  display: none; }

* {
  color: #111;
  background: none; }

.wrapper {
  padding-bottom: 0 !important;
  padding: 2rem; }

.header .inner .company-logo-link,
.header .inner .company-logo {
  display: block; }

.header .inner .company-logo {
  margin-bottom: 3rem; }

.hero .inner .profile-image-wrapper,
.hero .inner .profile-image {
  display: block; }

.hero .inner .profile-image-wrapper {
  margin-bottom: 3rem;
  width: 200px;
  display: block;
  position: relative; }
