
//==========  Colors  ==========//

// set our colour variables
// avoid using obvious declarative names as the colours may not always match the name
// e.g, $colour-orange
// 
// we also create lighter and darker tones for use with hover states, etc.

$color-primary: 		#00204e; 						// the brand colour
$color-primary-light:	lighten($color-primary, 5%);	// the brand colour - lighter
$color-primary-dark:	darken($color-primary, 5%);		// the brand colour - darker

$color-secondary: 		#cecbc4; 						// the accent colour
$color-secondary-light:	lighten($color-secondary, 5%);	// the accent colour - lighter
$color-secondary-dark:	darken($color-secondary, 5%);	// the accent colour - darker


$color-tertiary: 		#93afb1; 						// the tertiary colour
$color-tertiary-light:	lighten($color-tertiary, 5%);	// the tertiary colour - lighter
$color-tertiary-dark:	darken($color-tertiary, 5%);	// the tertiary colour - darker

$color-quaternary: 		#b8b4a5;
$color-quinary: 		#a6a49f;
$color-senary: 		#b8b4a5;
//==========  Tones  ==========//

// set grey tones variables
// these will typically be used for borders and form inputs
// again, we also create lighter and darker tones for use with hover states, etc.

$color-lightgrey:		#eee;							// a default light grey
$color-lightgrey-light:	lighten($color-lightgrey, 5%);	// a default light grey - lighter
$color-lightgrey-dark:	darken($color-lightgrey, 5%);	// a default light grey - darker

$color-midgrey:			#bbb;	 						// a default mid grey
$color-midgrey-light:	lighten($color-midgrey, 5%);	// a default mid grey - lighter
$color-midgrey-dark:	darken($color-midgrey, 5%);		// a default mid grey - darker

$color-darkgrey:		#85898a;						// a default dark grey
$color-darkgrey-light:	lighten($color-darkgrey, 5%);	// a default dark grey - lighter
$color-darkgrey-dark:	darken($color-darkgrey, 5%);	// a default dark grey - darker

$color-white: 			#fff;							// a default white
$color-black: 			#111;							// a default black

$accent-grey:			#e5e4e0;
$color-office-background: 	#3e4545; 	


//==========  Text colors  ==========//

// set our default text colours for titles, paragraphs, etc.

$color-text:	#5d5c58;								// body text colour
$color-sub:		$color-text;								// sub and intro text colour
$color-title:	$color-primary;							// heading text colour
$color-error: 	#e74c3c;								// error text colour
$header-bar: 	#071933;
